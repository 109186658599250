import { makeObservable } from "mobx";
import {action, observable, autorun, computed } from "mobx"



class DOEStore {

    constructor () {
        makeObservable(this, {
            SerialNumbers: observable,
            Switches: observable,
            uploadedFiles: observable,
            PostInsertionStatus: observable,
            Dropzones: observable,
            Config : observable,
            user : observable,
            updateConfig: action,
            updateName: action,
            updateState: action,
            updateSwitchState: action,
            updateFileNameState: action,
            updateFileStatusState: action,
            updatePostInsertionStatus: action,
            updateDropzoneActiveState : action,
            updateDropzoneDroppedState : action,
            setDroppedFilesToUpload: action,
            setFilesToBeDeleted: action,
            updateDroppedFilesUploadStatus: action,
            updateDropzoneFunctions: action,
            clearState: action,
            setStateToAfterLambdaInvoke: action,
            canSubmit: computed,
            stateForUpload: computed,
            fileUploadComplete: computed,
            PostInsertionStatusState: computed
          
        });
        // autorun(() => console.log(this.storeDetails));
    }

    updateState(type, station, value)
    {
        this.SerialNumbers[station][type] = value
    }

    updateName(value)
    {
        this.user['name'] = value
    }

    updateSwitchState(type, value)
    {
        this.Switches[type] = value
    }

    updateFileNameState(type, value)
    {
        this.uploadedFiles[type]["fileName"] = value
    }

    updateFileStatusState(type, value)
    {
        this.uploadedFiles[type]["status"] = value
    }

    updatePostInsertionStatus(type, value)
    {
        this.PostInsertionStatus[type] = value
    }

    updateDropzoneActiveState(type, value)
    {
        this.Dropzones[type]["active"] = value
    }

    updateDropzoneDroppedState(type, value)
    {
        this.Dropzones[type]["fileDropped"] = value
    }

    setDroppedFilesToUpload()
    {
        for (let dropzone in this.Dropzones)
        {
            if (this.Dropzones[dropzone]["active"])
            {
                this.Dropzones[dropzone]["uploadStatus"] = "waitingToUpload"
            }
        }
    }

    updateDroppedFilesUploadStatus(type, value)
    {
        this.Dropzones[type]["uploadStatus"] = value
    }

    updateDropzoneFunctions(type, functionName, value)
    {
        this.Dropzones[type][functionName] = value
    }

    setFilesToBeDeleted()
    {  
        for (let dropzone in this.Dropzones)
        {
            if (this.Dropzones[dropzone]["active"])
            {
                this.Dropzones[dropzone]["uploadStatus"] = "ReadyToDeleteFiles"
            }
        }
    }

    setStateToAfterLambdaInvoke(response)
    {
        this.PostInsertionStatus['response'] = response
        this.PostInsertionStatus['loading'] = false
        this.PostInsertionStatus['snackBarOpen'] = true
        this.PostInsertionStatus['dropzoneDisabled'] = false
    }

    updateConfig(type, value)
    {
        this.Config[type] = value
    }

    get stateForUpload()
    {
        return {'SerialNumbers' : this.SerialNumbers, 'files': this.uploadedFiles, 'user': this.user}
    }

    get PostInsertionStatusState()
    {
        return this.PostInsertionStatus
    }

    clearState()
    {
        this.SerialNumbers = {
            'Station1' : {'RobotSerial': '', 'BatterySerial' : '', 'DockSerial' : '', 'RobotFirmware' : '', 'DockFirmware' : '', 'isButtonActive' : true},
            'Station2' : {'RobotSerial': '', 'BatterySerial' : '', 'DockSerial' : '', 'RobotFirmware' : '', 'DockFirmware' : '', 'isButtonActive' : true},
            'Station3' : {'RobotSerial': '', 'BatterySerial' : '', 'DockSerial' : '', 'RobotFirmware' : '', 'DockFirmware' : '', 'isButtonActive' : true},
            'Station4' : {'RobotSerial': '', 'BatterySerial' : '', 'DockSerial' : '', 'RobotFirmware' : '', 'DockFirmware' : '', 'isButtonActive' : true},
            'Station5' : {'RobotSerial': '', 'BatterySerial' : '', 'DockSerial' : '', 'RobotFirmware' : '', 'DockFirmware' : '', 'isButtonActive' : true},
            'Station6' : {'RobotSerial': '', 'BatterySerial' : '', 'DockSerial' : '', 'RobotFirmware' : '', 'DockFirmware' : '', 'isButtonActive' : true},
            'Station7' : {'RobotSerial': '', 'BatterySerial' : '', 'DockSerial' : '', 'RobotFirmware' : '', 'DockFirmware' : '', 'isButtonActive' : true},
            'Station8' : {'RobotSerial': '', 'BatterySerial' : '', 'DockSerial' : '', 'RobotFirmware' : '', 'DockFirmware' : '', 'isButtonActive' : true}  
        }
    
        this.uploadedFiles = {
            'Charging'  : {'fileName' : '', 'status' : ''}, 
            'Discharge' : {'fileName' : '', 'status' : ''},
            'NSB'       : {'fileName' : '', 'status' : ''}
        }
    }

    get fileUploadComplete () {

        let uploadStatusComplete = true
        let atLeastOneFileUploaded = false
        for (let file in this.uploadedFiles)
        {  
            if (this.uploadedFiles[file]["fileName"])
            {
                atLeastOneFileUploaded = true
                if (this.uploadedFiles[file]["status"] != "done")
                {
                    uploadStatusComplete = false
                }
            }
        }

        return atLeastOneFileUploaded && uploadStatusComplete
    }


    // get store details
    get storeDetails () {
        let returnValue = ""

        for (let station in this.SerialNumbers)
        {
            
            returnValue += station + 
            " RobotSerial: " + this.SerialNumbers[station]["RobotSerial"] +
            " BatterySerial: " + this.SerialNumbers[station]["BatterySerial"] +
            " DockSerial: " + this.SerialNumbers[station]["DockSerial"] +
            " RobotFirmware: " + this.SerialNumbers[station]["RobotFirmware"] +
            " DockFirmware: " + this.SerialNumbers[station]["DockFirmware"] + 
            " isButtonActive: " + this.SerialNumbers[station]["isButtonActive"] + 
            "\n\n"
            
        }

        returnValue += "chargingfile: " + this.uploadedFiles["Charging"]["fileName"] + "Status: " + this.uploadedFiles["Charging"]["status"] +
        " dischargefile: " + this.uploadedFiles["Discharge"]["fileName"] + "Status: " + this.uploadedFiles["Discharge"]["status"] +
        " nsbfile: " + this.uploadedFiles["NSB"]["fileName"] + "Status: " + this.uploadedFiles["NSB"]["status"]


        return returnValue
      }

    get canSubmit() {
        let checkSubmit = true
        let isOneStationActive = false
        let isOneDropzoneActive = false


        for (let station in this.SerialNumbers)
        {
               if (this.SerialNumbers[station]["isButtonActive"])
               {
                   isOneStationActive = true
                   //Check that all fields are populated
                   if (!(this.SerialNumbers[station]["RobotSerial"] &&
                        this.SerialNumbers[station]["BatterySerial"] &&
                        this.SerialNumbers[station]["DockSerial"] &&
                        this.SerialNumbers[station]["RobotFirmware"] &&
                        this.SerialNumbers[station]["DockFirmware"]
                   ))
                   {
                        checkSubmit = false
                   }
               }
        }

        for (let dropzone in this.Dropzones)
        {
            if (this.Dropzones[dropzone]["active"])
            {
                isOneDropzoneActive = true
                if (!this.Dropzones[dropzone]["fileDropped"])
                {
                    checkSubmit = false
                }
            }
        }

        return checkSubmit && isOneStationActive && isOneDropzoneActive

    }

    logStoreDetails() {
       return console.log(this.storeDetails);
    }

    SerialNumbers = {
        'Station1' : {'RobotSerial': '', 'BatterySerial' : '', 'DockSerial' : '', 'RobotFirmware' : '', 'DockFirmware' : '', 'isButtonActive' : true},
        'Station2' : {'RobotSerial': '', 'BatterySerial' : '', 'DockSerial' : '', 'RobotFirmware' : '', 'DockFirmware' : '', 'isButtonActive' : true},
        'Station3' : {'RobotSerial': '', 'BatterySerial' : '', 'DockSerial' : '', 'RobotFirmware' : '', 'DockFirmware' : '', 'isButtonActive' : true},
        'Station4' : {'RobotSerial': '', 'BatterySerial' : '', 'DockSerial' : '', 'RobotFirmware' : '', 'DockFirmware' : '', 'isButtonActive' : true},
        'Station5' : {'RobotSerial': '', 'BatterySerial' : '', 'DockSerial' : '', 'RobotFirmware' : '', 'DockFirmware' : '', 'isButtonActive' : true},
        'Station6' : {'RobotSerial': '', 'BatterySerial' : '', 'DockSerial' : '', 'RobotFirmware' : '', 'DockFirmware' : '', 'isButtonActive' : true},
        'Station7' : {'RobotSerial': '', 'BatterySerial' : '', 'DockSerial' : '', 'RobotFirmware' : '', 'DockFirmware' : '', 'isButtonActive' : true},
        'Station8' : {'RobotSerial': '', 'BatterySerial' : '', 'DockSerial' : '', 'RobotFirmware' : '', 'DockFirmware' : '', 'isButtonActive' : true}  
    }
    Switches = {'RobotFirmware' : false, 'DockFirmware' : false}

    uploadedFiles = {
        'Charging'  : {'fileName' : '', 'status' : ''}, 
        'Discharge' : {'fileName' : '', 'status' : ''},
        'NSB'       : {'fileName' : '', 'status' : ''}
    }

    PostInsertionStatus = {'loading' : false, "snackBarOpen": false, "response": "", "dropzoneDisabled": false, "TestUpload" : true, "uploadStatus": "waitingToStart" }

    Dropzones = {
        'Charging'  : {'active' : true, 'fileDropped' : false, 'uploadStatus' : "", removeFunction : null, uploadFunction: null}, 
        'Discharge' : {'active' : true, 'fileDropped' : false, 'uploadStatus' : "", removeFunction : null, uploadFunction: null},
        'NSB'       : {'active' : true, 'fileDropped' : false, 'uploadStatus' : "", removeFunction : null, uploadFunction: null}
    }

    Config = {
        'doePipelineConnector_API' : "", 
        "getPresignedFileUrl_API": ""
    }

    user = {
        'name' : ""
    }
}

export default DOEStore;